import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { CompleteTaskInputModalComponentService } from '../../services/modals/complete-task-input-modal.component.service';

@Component({
  selector: 'dougs-complete-task-input-modal',
  templateUrl: './complete-task-input-modal.component.html',
  styleUrls: ['./complete-task-input-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ControlFormFieldDirective,
    ModalFooterDirective,
    ButtonComponent,
    PanelInfoComponent,
    NgIf,
    NgClass,
  ],
  providers: [CompleteTaskInputModalComponentService],
})
export class CompleteTaskInputModalComponent {
  constructor(public readonly completeTaskInputModalComponentService: CompleteTaskInputModalComponentService) {}
}
