import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { CompletionReason } from '@dougs/task/dto';

@Injectable()
export class CompleteTaskInputModalComponentService {
  formGroup: FormGroup = new FormGroup({
    message: new FormControl('', [Validators.required]),
  });

  constructor(
    @Inject(MODAL_DATA) public data: CompletionReason,
    private readonly modalRef: ModalRef<string, CompletionReason>,
  ) {}

  get message(): FormControl {
    return this.formGroup.get('message') as FormControl;
  }

  onSubmit(): void {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      this.modalRef.close(this.message.value);
    }
  }
}
