<form
  [formGroup]="completeTaskInputModalComponentService.formGroup"
  (ngSubmit)="completeTaskInputModalComponentService.onSubmit()"
>
  <div dougsModalTitle>
    <h6>{{ completeTaskInputModalComponentService.data.messageTitle || 'Merci de détailler la raison' }}</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-panel-info
      *ngIf="completeTaskInputModalComponentService.data.messageWarning as messageWarning"
      class="mb-16"
      type="warning"
    >
      <i class="fal fa-exclamation-triangle color-warning mr-4"></i>
      {{ messageWarning }}
    </dougs-panel-info>
    <textarea
      dougsFormFieldControl
      [placeholder]="completeTaskInputModalComponentService.data.messageTitle || 'Merci de détailler la raison'"
      formControlName="message"
    ></textarea>
  </div>
  <div dougsModalFooter>
    <dougs-button [dougsModalClose]="false" color="secondary">Annuler</dougs-button>
    <dougs-button type="submit">Ajouter</dougs-button>
  </div>
</form>
